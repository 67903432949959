






































import { Page, Pageable, PerfilBase } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { FindPerfilUseCase } from '@/usecases/perfil'
import axios, { CancelTokenSource } from 'axios'
import { Vue, Component, Watch } from 'vue-property-decorator'

@Component({
	components: {
		SeletorDeConfiguracaoDePerfis,
	},
})

export default class SeletorDeConfiguracaoDePerfis extends Vue {
	selecionado?: PerfilBase
	perfilBases: PerfilBase[] = [];
	cancelToken: CancelTokenSource | null = null
	findPerfilUseCase = new FindPerfilUseCase()
	pagina: Page<PerfilBase> | null = null
	busca: string | null = null
	carregando = false
	pageable: Pageable = {
		page: 0,
		size: 3,
	}

	onSelect(perfil: PerfilBase){
		this.selecionado = perfil;
		this.$emit('selecionado', perfil);
		this.$router.push('/perfis/' + perfil.id + '/novo')
	}

	created() {
		this.carregarSelecao()
	}

	extrairId(objeto: PerfilBase | string): string {
		return typeof objeto === 'object'
			? objeto.id
			: objeto
	}

	async carregarSelecao() {
		if(!this.selecionado) {
			return this.find()
		}

		if (this.cancelToken) {
			this.cancelToken.cancel('Requisição anterior Cancelada')
		}

		this.cancelToken = axios.CancelToken.source()
		Array.isArray(this.selecionado)
			? (this.selecionado as (string | PerfilBase)[]).map(this.extrairId)
			: [this.extrairId(this.selecionado)]
	}

	async find() {
		if(this.cancelToken){
			this.cancelToken.cancel("Requisição anterior cancelada")
		}

		this.cancelToken = axios.CancelToken.source()
		try{
			this.carregando = true
			this.pagina = await this.findPerfilUseCase.findAll({
				page: this.pageable.page || 0,
				size: this.pageable.size,
				busca: this.busca || undefined,
			},
			{
				cancelToken: this.cancelToken.token,
			})
			this.pagina.content.forEach(PerfilBase => {
				if (this.perfilBases.some(({ id }) => PerfilBase.id === id)) return
				this.perfilBases.push(PerfilBase)		
			})				
				
		} catch(error){
			if(axios.isCancel(error)) return
			this.pagina = null
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}
	
	@Watch('busca', { deep: true })
	onChangeBusca(busca: string, buscaAnterior: string) {
		this.pageable = {
			...this.pageable,
			page: 0,
		}
		if (busca === buscaAnterior) {
			this.carregando = false
			return
		}
		this.find()
	}

	get carregouTodosOsPerfis() {
		return this.pagina?.last || false
	}

	get noDataMessage() {
		if (this.carregando) {
			return 'Carregando...'
		}
		if (!this.busca) {
			return 'Digite para consultar um perfil cópia...'
		}
		return '$vuetify.noDataText'
	}
}

