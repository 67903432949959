



































import { Vue, Component, Prop } from 'vue-property-decorator'
import SeletorDeCliente from '../clientes/SeletorDeCliente.vue';
import RangeDatePicker from '@/components/ui/RangeDatePicker.vue';
import SeletorDeConfiguracaoDePerfis from '@/components/perfil/SeletorDeConfiguracaoDePerfis.vue';
import { CancelTokenSource } from 'axios';
import { FindPerfilUseCase } from '@/usecases'
import { DataOptions } from 'vuetify';
import { Perfil } from '@/models';
import type { PerfilBase } from '@/models';

@Component({
	components:{
		SeletorDeCliente,
		RangeDatePicker,
		SeletorDeConfiguracaoDePerfis,
	},
})

export default class DialogoDeCopiaDePerfis extends Vue {
	@Prop() selecionado?: PerfilBase;
	idCapturado: PerfilBase[] = [];
	perfilSelecionado: PerfilBase[] = [];
	perfilBases: PerfilBase[] = []
	cancelToken: CancelTokenSource | null = null
	mostra = false
	totalRegistros = -1
	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,		
	}

	mostrar(){
		this.mostra = true
	}

	ocultar(){
		this.mostra = false
	}

	carregando = false
	findUseCase = new FindPerfilUseCase()
	busca = ''
	perfis: Perfil[] = []

	get itens() {
		return this.perfis
	}
}

